import React, { useState, useRef } from "react";

import { Card, Col, Modal, Form } from "react-bootstrap";

import emailjs from "@emailjs/browser";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGift } from '@fortawesome/free-solid-svg-icons'


import { db } from "../../utils/firebase";
import { doc, setDoc } from "firebase/firestore";

//Styles
import "./Card.css";

const ListCard = ({ item }) => {

  const initialFormValue = {
    persons: undefined,
    texto: undefined,
  };

  const [show, setShow] = useState(false);

  const [emailForm, setEmailForm] = useState(initialFormValue);

  const form = useRef();

  const hasClicking = () => setShow(true);
  const handleClose = () => setShow(false);

  const rendermyLinks = () => {

    const listRender = [];
    if (item.urlLink.length > 0) {

      listRender.push(<Card.Subtitle><Card.Text>Donde conseguir el producto:</Card.Text></Card.Subtitle>)

      for (let i = 0; i < item.urlLink.length; i++) {
        if (item.urlLink[i] !== undefined) {
          listRender.push(<Card.Link href={item.urlLink[i].url}>{item.urlLink[i].nombre} <br /></Card.Link>)
        }
      }

    }


    return listRender;
  }

  const handleOnChangeInput = (event) => {
    let { name, value } = event.target;
    setEmailForm({ ...emailForm, [name]: value });
  };


  const sendGift = async () => {

    await setDoc(doc(db, "dearGifts", item.id), {
      img: item.img,
      nombre: item.nombre,
      quantity: (item.quantity - 1),
      texto: item.texto,
      urlLink: item.urlLink,
    }).then(
      handleClose()
    );
  }

  const sendEmail = (templateEmail) => {

    emailjs
      .send("service_h8tb91v", "template_f5oejrx", templateEmail, {
        publicKey: "9AwUeEnTIydh0LZqo",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          handleClose()
        },
        (error) => {
          console.log("FAILED...", error.text);
          handleClose()
        }
      );
  };

  const handleGift = (evnr) => {

    sendGift();
    sendEmail({
      from_name: emailForm.persons,
      message: emailForm.texto,
      from_id: item.id,
      reply_to: "noelia.be@hotmail.com"
    });

  };


  return (

     <Col md={2} lg={3} className="mb-3"> 
      <Card style={{ width: "18rem" }} className="text-center w-100 h-100 cardboda">
      
        <Card.Img
          variant="top"
          src={item.img}
          className="img-thumbnail list-card-detail cardbodaimagen"
        />
         
        <Card.Body>
        <Card.Title className="mt-1" >   <p className="ph3">{item.nombre}</p> </Card.Title>
          <Card.Text></Card.Text>
          <p>{item.texto}</p>
          {rendermyLinks(item)}       
          </Card.Body>
        <Card.Footer className="cardbodabottom">
          <Col className="d-flex justify-content-center" >
            <button type="button" className="button-wish-color button-wish button-card-size rounded shadow-sm" onClick={hasClicking}><FontAwesomeIcon icon={faGift} /> Regalarnos</button>
          </Col>
        </Card.Footer>
  </Card>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>🎁 ¡Muchas gracias por este regalo! 🎁</Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
        No nos alcanzan las palabras para expresar cuánto apreciamos el esfuerzo y cariño que pusiste en cumplir este deseo.
Nos gustaría que nos cuentes quienes son las personas que participan y nos dejen una dedicatoria que atesoraremos como un recuerdo de nuestra noche especial 🌠
          <Form className="mt-4" ref={form}>
            <Form.Group className="mb-3" controlId="EmailForm.PersonSend">
              < Form.Label>¿Quienes participan del regalo?</Form.Label>
              <Form.Control
                type="textarea"
                rows={3}
                value={emailForm.persons}
                name="persons"
                onChange={handleOnChangeInput}
                autoFocus
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="EmailForm.ControlTextarea"
            >
              <Form.Label>Mensaje para los novios</Form.Label>
              <Form.Control as="textarea" name="texto" rows={3} value={emailForm.texto} onChange={handleOnChangeInput} />
            </Form.Group>
          </Form>
        
        </Modal.Body>
        
        <Modal.Footer>
          
          <button type="button" className="button-wish-color button-wish rounded shadow-sm" onClick={handleGift}> Regalar</button>
          <button type="button" className="btn-warning rounded shadow-sm" onClick={handleClose}> Seguir buscando regalo</button>
        </Modal.Footer>
      
      </Modal>
</Col>
  );
};

export default ListCard;