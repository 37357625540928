import React from "react";

import { Container, Row } from "react-bootstrap";

const itemListCard = () => {
  return (
    <Container>
        <Row>
            Estamos cargando algo
        </Row>
    </Container>
  );
};

export default itemListCard;