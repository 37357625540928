import React from 'react';

import './index.css';

//Routers
import ItemListContainer from './components/containers/ItemListContainer'

const App = () => {

        return (
                <div className='Boody'>
                        <ItemListContainer />
                </div>
        );
}

export default App;