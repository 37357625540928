//// Import the functions you need from the SDKs you need
//import { initializeApp } from "firebase/app";
//// TODO: Add SDKs for Firebase products that you want to use
//// https://firebase.google.com/docs/web/setup#available-libraries

//import { getDatabase } from "firebase/database";

//// Your web app's Firebase configuration
//const firebaseConfig = {
//  apiKey: "AIzaSyAFhW_18rCcrgzNekbOtGkD29t2fpAalOc",
//  authDomain: "dear-wedding.firebaseapp.com",
//  projectId: "dear-wedding",
//  storageBucket: "dear-wedding.appspot.com",
//  messagingSenderId: "123548813965",
//  appId: "1:123548813965:web:5fbe6bc822573cff3030c4", 
//};

//// Initialize Firebase
// const app = initializeApp(firebaseConfig);
//// const app = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
//// Initialize Realtime Database and get a reference to the service
//export const db = getDatabase(app);
////Ver código ejemplo sanbox que no utiliza getDatabase


// Import the functions you need from the SDKs you need
//import firebase from "firebase/app";
//import "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAFhW_18rCcrgzNekbOtGkD29t2fpAalOc",
  authDomain: "dear-wedding.firebaseapp.com",
  databaseURL: "https://dear-wedding-default-rtdb.firebaseio.com",
  projectId: "dear-wedding",
  storageBucket: "dear-wedding.appspot.com",
  messagingSenderId: "123548813965",
  appId: "1:123548813965:web:5fbe6bc822573cff3030c4",
};

// Initialize Firebase
//const app = initializeApp(firebaseConfig);
// const app = !firebase.apps.length
//  ? firebase.initializeApp(firebaseConfig)
//  : firebase.app();

// export const ItemCardsCollection = firebase
//  .firestore(app)
//  .collection("dearGifts");

// export const fauth = firebase.auth();

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export { db };