import React from "react";

import { CardGroup, Row } from "react-bootstrap";

import ListCard from "./Card";

const itemListCard = ({ iTems }) => {
  return (
    <CardGroup>
      <Row xs={1} sm={2} md={3} lg={4} className="justify-content-center">
        {iTems.map((item) => { 
          if ( item.quantity > 0) {
          return <ListCard key={item.id} item={item} />;
        }
        })}
      </Row>
    </CardGroup>
  );
};

export default itemListCard;