import React, { useEffect, useState } from "react";


import ItemList from "../component/ItemsList";
import ItemListRecharge from "../component/ItemListRecharge.js"

import { db } from "../../utils/firebase";

import { collection, query, orderBy, onSnapshot } from "firebase/firestore";

import { Container } from "react-bootstrap";

const ItemListCardContainer = () => {

  const [items, setItems] = useState([]);


  /* function to get all items from firestore in realtime */
  useEffect(() => {
    const q = query(collection(db, "dearGifts"), orderBy("texto"));
    onSnapshot(q, (response) => {
      setItems(
        response.docs.map((item) => ({
          id: item.id,
          ...item.data(),
        }))
      );
    });
  }, []);

  return (
    <section>
      <Container fluid="md" className="mt-5">
        { ( items !== undefined ) ? <ItemList iTems={items} /> : <ItemListRecharge />}
        
      </Container>
    </section>
  );
};

export default ItemListCardContainer;
